export const navToggle = (function(){

	let my = {};

	function _addDoubleClick() {
		[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
			el.addEventListener('click', e => {
				if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
					if (!el.hasAttribute('clicked')) {
						e.preventDefault();
						el.setAttribute('clicked', true);
					}
				}
			});
		});
	}

	function _mobileDrop() {
		document.querySelectorAll('.nav_item').forEach(item => {
			const has_drop = item.querySelector('.has_drop');
			const dropdown = item.querySelector('.dropdown');
			if (has_drop && dropdown) {
				has_drop.addEventListener('click', (event) => {
					event.preventDefault();
					event.stopPropagation();
					
					document.querySelectorAll('.nav_item').forEach(otherItem => {
						if (otherItem !== item) {
							const otherHasDrop = otherItem.querySelector('.has_drop');
							const otherDropdown = otherItem.querySelector('.dropdown');
							if (otherHasDrop) {
								otherHasDrop.classList.remove('clicked');
							}
							if (otherDropdown) {
								otherDropdown.classList.remove('open');
							}
						}
					});
	
					has_drop.classList.toggle('clicked');
					dropdown.classList.toggle('open');
				});
	
				dropdown.addEventListener('click', (event) => {
					event.stopPropagation();
				});
			}
		});
	
		document.addEventListener('click', (event) => {
			if (!event.target.closest('.nav_item')) {
				document.querySelectorAll('.has_drop.clicked, .dropdown.open').forEach(el => {
					el.classList.remove('clicked', 'open');
				});
			}
		});
	}

	function _mobileToggle() {
		document.getElementById('site_nav_toggle').addEventListener('click', function(e){
			let clickedButton = e.currentTarget;
			let nav = document.getElementById('header_nav');

			clickedButton.toggleAttribute('open');
			nav.toggleAttribute('open');
		});
	}

	my.init = function(){
		_addDoubleClick();
		_mobileToggle();
		_mobileDrop();
	};

	return my;
})();